*,
::after,
::before {
  box-sizing: border-box;
}

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Cabin&family=Roboto+Condensed:wght@400;700&display=swap");

html {
  font-size: 100%;
} /*16px*/

:root {
  /* colors */
  --primary-50: #eff6ff;
  --primary-100: #dbeafe;
  --primary-200: #1d9ddd;
  --primary-300: #1778a8;
  --primary-400: #13658e;
  --primary-500: #11587b;
  --primary-600: #0e4967;
  --primary-700: #0b3d56;
  --primary-800: #0a3a52;
  --primary-900: #073247;
  --secondary: #50821d;

  /* grey */
  --grey-50: #f0f4f8;
  --grey-100: #d9e2ec;
  --grey-200: #bcccdc;
  --grey-300: #9fb3c8;
  --grey-400: #829ab1;
  --grey-500: #627d98;
  --grey-600: #486581;
  --grey-700: #334e68;
  --grey-800: #243b53;
  --grey-900: #102a43;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red: #a42933;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* fonts  */
  --headingFont: "Roboto Condensed", Sans-Serif;
  --bodyFont: "Cabin", Sans-Serif;
  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 500px;
  --fluid-width: 90vw;
  --breakpoint-lg: 992px;
  --nav-height: 6rem;
  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body {
  background: var(--backgroundColor) !important;
  font-family: var(--bodyFont) !important;
  font-weight: 400 !important;
  line-height: 1.75 !important;
  color: var(--textColor) !important;
}

p {
  margin-bottom: 1.5rem;
  max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
  color: var(--primary-500);
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text-small {
  font-size: var(--small-text);
}

a {
  text-decoration: none;
  letter-spacing: var(--letterSpacing);
}
a,
.btnn {
  line-height: 1.15;
  text-decoration: none;
}
button:disabled {
  cursor: not-allowed;
}
ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}
/* buttons */

.btnn {
  cursor: pointer;
  color: var(--white);
  background: var(--primary-500);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}
.btnn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}
.btnn-hipster {
  color: var(--primary-500);
  background: var(--primary-200);
}
.btnn-hipster:hover {
  color: var(--primary-200);
  background: var(--primary-700);
}
.btnn-block {
  width: 100%;
}
.btnn-hero {
  font-size: 1.25rem;
  padding: 0.5rem 1.25rem;
}
.btnn-danger {
  background: var(--red-light);
  color: var(--red-dark);
}
.btnn-danger:hover {
  background: var(--red-dark);
  color: var(--white);
}
/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--borderRadius);
  text-align: center;
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}
/* form */

.form {
  width: 90vw;
  max-width: var(--fixed-width);
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 3rem auto;
  transition: var(--transition);
}
.form:hover {
  box-shadow: var(--shadow-4);
}
.form-label {
  display: block;
  font-size: var(--smallText);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}
.form-input,
.form-textarea,
.form-select {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  border: 1px solid var(--grey-200);
}
.form-input,
.form-select,
.btnn-block {
  height: 35px;
}
.form-row {
  margin-bottom: 1rem;
}

.form-textarea {
  height: 7rem;
}
::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}
.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}
/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 2s linear infinite;
}
.loading-center {
  margin: 0 auto;
}
/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-500);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
}

.container {
  width: var(--fluid-width);
  max-width: var(--max-width);
  margin: 0 auto;
}
.full-page {
  min-height: 100vh;
}

.coffee-info {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1rem;
  letter-spacing: var(--letterSpacing);
}
.coffee-info span {
  display: block;
}
.coffee-info a {
  color: var(--primary-500);
}

@media screen and (min-width: 992px) {
  .coffee-info {
    text-align: left;
  }
  .coffee-info span {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

.Toastify__toast {
  text-transform: capitalize;
}

/* .abs-user{
  position: absolute;
} */
.relative-user {
  position: absolute;
  z-index: 1;
  margin: auto;
}
.btn:focus {
  outline: none !important;
}
textarea {
  width: 100%;
  height: 100px;
}

/* uploader  */

.custom-button {
  right: 0px;
  top: 0px;
  position: absolute;
  background-color: red;
  border-radius: 50%;
  border: none;
  height: 20px;
  display: flex;
  width: 20px;
  color: white;
  align-items: center;
}

.smallImage {
  width: 90px;
  height: 90px;
}

.dotted {
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  position: relative;
  box-sizing: border-box;
}

.bg-blue {
  background-color: aliceblue;
  padding-inline: 16px;
  padding-block: 10px;
  border-radius: 5px;
}

.thumbnail-container img {
  max-width: 50%;
}
.thumbnail {
  max-width: 100% !important;
}

@media only screen and (max-width: 430px) {
  .fecoQm .content {
    height: auto !important;
    bottom: auto !important;
    top: auto !important;
    right: 164px !important;
  }
}

.input-group.input-group-sm.mb-3 input {
  z-index: -1 !important;
}
.modal-content {
  width: fit-content;
}
